var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
class QmCampaigns {
    constructor() {
        this.paths = Object.freeze({
            list: (formid) => `api/mangl/campaigns/list/${encodeURIComponent(formid)}`,
            unlink: (campaignid) => `api/mangl/campaigns/link/${encodeURIComponent(campaignid)}`,
            link: (campaignid, formid) => `api/mangl/campaigns/link/${encodeURIComponent(campaignid)}/${encodeURIComponent(formid)}`,
        });
    }
    /**
     * QmCampaigns.List "GET api/mangl/campaigns/list/{formId}"
     * @param {Guid} formid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CampaignModel[]>>}
     */
    list(formid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/campaigns/list/${formid === undefined || formid === null ? "" : encodeURIComponent(formid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * QmCampaigns.Unlink "DELETE api/mangl/campaigns/link/{campaignId}"
     * @param {Guid} campaignid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    unlink(campaignid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/campaigns/link/${campaignid === undefined || campaignid === null ? "" : encodeURIComponent(campaignid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * QmCampaigns.Link "POST api/mangl/campaigns/link/{campaignId}/{formId}"
     * @param {Guid} campaignid
     * @param {Guid} formid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    link(campaignid, formid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/campaigns/link/${campaignid === undefined || campaignid === null ? "" : encodeURIComponent(campaignid)}/${formid === undefined || formid === null ? "" : encodeURIComponent(formid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const qmCampaigns = new QmCampaigns();
export { qmCampaigns as default, QmCampaigns };
