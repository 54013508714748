/**
 * Id of the default form, extra rules/limitations may be added to this form
 * @type {string}
 */
export const defaultFormId = '00000000-0000-0000-0000-000000000001';
/**
 * Returns true when the form entity or id is the default form
 * @param {string | {id: string}} formOrId
 * @return {boolean}
 */
export function isDefaultForm(formOrId) {
    // Satisfy ts -> we should theoretically never be invoked with an undefined form/id
    if (formOrId === undefined)
        return false;
    formOrId = typeof formOrId === 'string' ?
        formOrId :
        formOrId.id;
    return formOrId === defaultFormId;
}
