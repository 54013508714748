import { watch } from 'vue';
const useAutofill = (speaker1, speaker2, proximity, hasSecondGroup) => watch([speaker1, speaker2, proximity], (current, previous) => {
    // when a proximity has been configured, speaker values will start affecting eachother
    if (!hasSecondGroup.value || !proximity.value)
        return;
    const speaker1Changed = previous[0] !== current[0];
    const speaker2Changed = previous[1] !== current[1];
    const proximityChanged = previous[2] !== current[2];
    // when either speaker is explicitly set to both, force both speakers to both
    if (((speaker1Changed || proximityChanged) && speaker1.value === 'both') ||
        ((speaker2Changed || proximityChanged) && speaker2.value === 'both')) {
        speaker1.value = 'both';
        speaker2.value = 'both';
        return;
    }
    // speaker1/speaker2 should always equal unless dealing with an 'answer'
    // answers should always have differing speakers
    if (proximity.value === 'answer') {
        // when our proximity changed, simply take speaker1 as 'base'
        const speaker = speaker1Changed || proximityChanged ?
            speaker1 :
            speaker2;
        const otherSpeaker = speaker === speaker1 ?
            speaker2 :
            speaker1;
        otherSpeaker.value = speaker.value === 'agent' ? 'customer' : 'agent';
        return;
    }
    // simply force the non-changed speaker to follow the çhanged speaker's value
    if (speaker1Changed || proximityChanged)
        speaker2.value = speaker1.value;
    if (speaker2Changed)
        speaker1.value = speaker2.value;
});
export default useAutofill;
